// Colors
$white: #fff !default;
$black: #000 !default;
%clearfix {
  &:before,
  &:after {
    content: "";
    display: table;
  }

  &:after {
    clear: both;
  }
}

@mixin inlineblock($valign: top) {
  display: inline-block;
  vertical-align: $valign;
}

@mixin centerer($horizontal: true, $vertical: true) {
  position: absolute;

  @if ($horizontal and $vertical) {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  } @else if ($horizontal) {
    left: 50%;
    transform: translate(-50%, 0);
  } @else if ($vertical) {
    top: 50%;
    transform: translate(0, -50%);
  }
}

@mixin absolute {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

@mixin ghostVerticalAlign() {
  &:before {
    content: "";
    display: inline-block;
    vertical-align: middle;
    height: 100%;
    width: 0.1px;
  }
}

@mixin ghostHorizontalAlign() {
  text-align: justify;
  text-justify: distribute;

  &:after {
    content: "";
    display: inline-block;
    width: 100%;
  }
}

@mixin input-placeholder {
  &.placeholder {
    @content;
  }

  &:-moz-placeholder {
    @content;
  }

  &::-moz-placeholder {
    @content;
  }

  &:-ms-input-placeholder {
    @content;
  }

  &::-webkit-input-placeholder {
    @content;
  }
}

@function rem($size) {
  @return $size / 16px+0rem;
}

@mixin fontSize($size) {
  font-size: $size;
  font-size: rem($size);
}

@mixin desktop {
  @media screen and (max-width: 1200px) {
    @content;
  }
}

@mixin laptop {
  @media screen and (max-width: 992px) {
    @content;
  }
}

@mixin tablets {
  @media screen and (max-width: 768px) {
    @content;
  }
}

@mixin phones {
  @media screen and (max-width: 480px) {
    @content;
  }
}

@mixin imgGlitch($name, $intensity, $width, $height, $top, $left) {
  $steps: $intensity;
  @at-root {
    @for $i from 1 through 2 {
      @keyframes #{$name}-anim-#{$i} {
        @for $i from 0 through $steps {
          #{percentage($i*(1/$steps))} {
            clip: rect(
              random($height) + px,
              $width + px,
              random($height) + px,
              0
            );

            -ms-transform: scale(1.2) skewX(random(30) - 30 + deg);
            -webkit-transform: scale(1.2) skewX(random(30) - 30 + deg);
            transform: scale(1.2) skewX(random(10) - 10 + deg);

            -webkit-filter: saturate(9);
            filter: saturate(9);
          }
        }
      }
    }
  }

  > img {
    position: absolute;
    top: $top + px;
    left: $left + px;
  }
  > img:nth-child(2),
  > img:nth-child(3) {
    clip: rect(0, 0, 0, 0);
  }
  > img:nth-child(2) {
    left: ($left + 2) + px;
    animation: #{$name}-anim-1 2s infinite linear alternate-reverse;
  }
  > img:nth-child(3) {
    left: ($left - 2) + px;
    animation: #{$name}-anim-2 3s infinite linear alternate-reverse;
  }
}

































































































































.ie {
  .login-partner-wrap {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .login-partner {
      margin: 0;
    }
  }
}

.login-partner-wrap {
  min-height: calc(100vh - 168px);
  display: flex;
}
.login-partner {
  width: 565px;
  margin: auto;
  box-sizing: content-box;
  padding: 70px 160px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #fff;
  box-shadow: 0px 12px 24px #dddaeb;
  &-text {
    margin-bottom: 40px;
    p {
      font-size: 14px;
      line-height: 22px;
      text-align: center;
      color: #000;
    }
  }
  .logo {
    margin-bottom: 40px;
    &:hover {
      opacity: 1;
    }
  }
  .prime-title {
    text-align: center;
    & + .login-partner-text {
      margin-top: 15px;
    }
    & + form {
      margin-top: 35px;
    }
    h1 {
      margin: 0;
      font-weight: 700;
      font-size: 26px;
      color: #000;
    }
  }

  form {
    width: 325px;
    margin: 0 auto;
    input {
      font-size: 14px;
      line-height: 20px;
      color: #333;
    }
    .form-success {
      text-align: center;
      margin-top: 30px;
    }
    .form-item {
      & + .form-item-checkbox {
        margin-top: 25px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .form-item {
          & + .erroe-massage {
            margin-left: 25px;
          }
        }
      }
    }

    .erroe-massage {
      font-size: 12px;
      line-height: 15px;
      text-align: right;
      color: #f5383b;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    button {
      width: 100%;
      & + a {
        margin-top: 20px;
      }
    }
    a {
      display: inline-block;
      font-size: 11px;
      text-align: center;
      text-decoration-line: underline;
      color: #1e72c2;
    }
  }
}
